import { format } from "date-fns";
import { geoPath, geoAlbersUsa, json, projection, scaleLinear, scaleQuantize, select } from "d3";
import { debounce, get } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { STATES } from "@upsolve/shared";
import { theme } from "@upsolve/ui";

import realTimeCaseImpactMapData from "../../../static/geo/caseImpactMapData.json";

const CURRENT_YEAR = new Date().getFullYear();

// Manually add 2017 cases for 2018 NY>USA expansion visual effect
const caseImpactMapData = [...realTimeCaseImpactMapData];
for (let i = 1; i < 30; i += 1) {
  caseImpactMapData.push({
    id: i,
    createdAt: new Date("2017-06-01"),
    filedAt: new Date("2017-06-01"),
    districtAbbrev: "nysb",
    latitude: 40.688,
    longitude: -73.993,
    name: "Kisha F",
    debtTotal: 40000,
  });
}

function getCaseCountsByState(year) {
  return caseImpactMapData
    .filter((c) => c.filedAt && new Date(c.filedAt).getFullYear() <= year)
    .reduce((obj, cse) => {
      if (!cse.districtAbbrev) return obj;
      // Key'ing on full state name bc geojson feature property uses full state name
      const caseState = STATES[cse.districtAbbrev.slice(0, 2).toUpperCase()];
      if (!obj[caseState]) obj[caseState] = 0;
      obj[caseState] = obj[caseState] + 1;
      return obj;
    }, {});
}
const mostCasesFileInAStateEver = Math.max(...Object.values(getCaseCountsByState(CURRENT_YEAR)));

/**
 * ImpactMap
 * - v0: Render 2017-2020 with hotspot state colors + green pins
 * - v1: Render 2017-2022 with hotspot state colors + white pins
 *
 * @component
 * @version 1
 */
const ImpactMap = (props) => {
  const [stateGeoData, setStateGeoData] = useState(null);
  const [fitDimensions, setFitDimensions] = useState([]);
  const [viewYear, setViewYear] = useState(CURRENT_YEAR);
  const [viewingTooltip, setViewingTooltip] = useState(null);
  const svgRef = useRef();
  const wrapperRef = useRef();

  function drawStates(year) {
    const svg = select(svgRef.current);
    const projection = geoAlbersUsa().fitSize(fitDimensions, stateGeoData);
    const pathGenerator = geoPath().projection(projection);
    // - Count cases done within this year's time frame
    const caseCountsByState = getCaseCountsByState(year);
    const fillColorScale = scaleLinear()
      .domain([0, mostCasesFileInAStateEver / 2])
      .range([theme.colors.brand[900], theme.colors.brand[500]])
      .clamp(true);
    const strokeColorScale = scaleLinear()
      .domain([0, mostCasesFileInAStateEver / 2])
      .range([theme.colors.brand[900], theme.colors.brand[300]])
      .clamp(true);
    // - Remove existing states and draw new ones
    svg.selectAll(".state-geo").remove();
    svg
      .selectAll(".state-geo")
      .data(stateGeoData.features)
      .enter()
      .append("path")
      .attr("class", "state-geo")
      .attr("d", pathGenerator)
      .attr("fill", "#FFF")
      .attr("stroke", "#FFF")
      .transition()
      .duration(1500)
      .attr("fill", (feature) => {
        if (!caseCountsByState[feature.properties.name]) return "#FFF";
        return fillColorScale(caseCountsByState[feature.properties.name]);
      })
      .attr("stroke", (feature) => {
        if (!caseCountsByState[feature.properties.name]) return "none";
        return strokeColorScale(caseCountsByState[feature.properties.name]);
      });
  }

  function handleMouseOver(event, cse) {
    if (viewingTooltip == null || viewingTooltip.case.id != cse.id) {
      setViewingTooltip({ x: event.pageX, y: event.pageY, case: cse });
    }
  }
  const handleMouseOut = debounce(() => {
    setViewingTooltip(null);
  }, 2000);

  function placeCaseMarkers(year) {
    const projection = geoAlbersUsa().fitSize(fitDimensions, stateGeoData);
    const svg = select(svgRef.current);
    // - Delete existing markers
    svg.selectAll(".marker--case-filed").remove();
    // New Case Markers
    const newMarkers = svg
      .selectAll(".marker--case-filed")
      .data(
        caseImpactMapData.filter((c) => {
          // - Guam broke the projection, so filter out anything outside 50 states
          if (c.longitude > 16 && c.latitude > -161) return false;
          // - Filter by year togglers
          if (year) return c.filedAt && new Date(c.filedAt).getFullYear() <= year;
          return true;
        })
      )
      .enter()
      .append("svg:path")
      .attr("class", "marker--case-filed")
      .attr("d", "M0,0l-8.8-17.7C-12.1-24.3-7.4-32,0-32h0c7.4,0,12.1,7.7,8.8,14.3L0,0z")
      .attr("fill", theme.colors.white[900])
      .attr("stroke", theme.colors.brand[700])
      .attr("stroke-width", 1)
      .attr(
        "transform",
        ({ latitude, longitude }) =>
          `translate(${projection([longitude, latitude])[0]},${projection([longitude, latitude])[1]}) scale(0)`
      );
    // - Breakout newMarkers so we can transition...
    newMarkers
      .transition()
      .duration(500)
      .attr(
        "transform",
        ({ latitude, longitude }) =>
          `translate(${projection([longitude, latitude])[0]},${projection([longitude, latitude])[1]}) scale(0.45)`
      );
    // - And attach event handlers
    newMarkers.on("mouseover", handleMouseOver).on("mouseout", handleMouseOut);
  }

  function setupImpactMap(year) {
    // - Setup New State Heatmaps
    drawStates(year);
    // - Place Case Markers
    placeCaseMarkers(year);
    // - Save Year to State
    setViewYear(year);
  }

  // ON MOUNT SAVE DATA TO STATE
  useEffect(() => {
    json("/geo/us-states.json").then((data) => {
      const { width, height } = wrapperRef.current.getBoundingClientRect();
      setFitDimensions([width, height]);
      setStateGeoData(data);
    });
  }, []);
  // POST-MOUNT SETUP MAP
  useEffect(() => {
    // - Once state geo and dimensions are on state, we can create projections as needed
    if (stateGeoData && fitDimensions) setupImpactMap(CURRENT_YEAR);
  }, [stateGeoData, fitDimensions]);

  // RENDER
  return (
    <StyledImpactMap ref={wrapperRef}>
      {/* For some reason I needed to do this inline. w/ styled components it was causing style rerenders */}
      <div
        className="impact-map__tool-tip"
        style={{
          display: viewingTooltip ? "block" : "none",
          top: ((viewingTooltip || {}).y || 0) - 110,
          left: ((viewingTooltip || {}).x || 0) - 100,
        }}
      >
        {get(viewingTooltip, "case.name") && <p>{get(viewingTooltip, "case.name")}.</p>}
        {get(viewingTooltip, "case.filedAt") && (
          <p>Filed {format(new Date(get(viewingTooltip, "case.filedAt")), "MMM d, yyyy")}</p>
        )}
        {!get(viewingTooltip, "case.filedAt") && get(viewingTooltip, "case.createdAt") && (
          <p>Started {format(new Date(get(viewingTooltip, "case.createdAt")), "MMM d, yyyy")}</p>
        )}
        {get(viewingTooltip, "case.debtTotal") && (
          <p>
            Discharged $
            {Number(get(viewingTooltip, "case.debtTotal")).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        )}
      </div>
      <figure className="impact-map__wrapper">
        <svg ref={svgRef}></svg>
      </figure>
    </StyledImpactMap>
  );
};

const StyledImpactMap = styled.div`
  width: 100%;
  height: auto;
  min-height: 540px;
  margin: 0 auto;
  .impact-map__years {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: relative;
    top: 32px;
    button {
      transition: 100ms;
      margin: 0;
      padding: 0.5em 1em;
      outline: none;
      background: white;
      border-radius: 20px;
      border: 1px solid ${(props) => props.theme.colors.brand[700]};
      color: ${(props) => props.theme.colors.brand[500]};
      font-size: 14px;
      font-weight: 700;
      &:hover {
        cursor: pointer;
      }
      &.active {
        border: 1px solid ${(props) => props.theme.colors.brand[300]};
        border-bottom: 2px solid ${(props) => props.theme.colors.brand[300]};
        background: ${(props) => props.theme.colors.brand[500]};
        color: white;
      }
    }
    .impact-map__years__buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 5;
    }
    .impact-map__years__slider {
      height: 6px;
      width: 100%;
      position: relative;
      background: ${(props) => props.theme.colors.brand[700]};
      top: -18px;
    }
  }
  .impact-map__wrapper {
    position: relative;
  }
  svg {
    overflow: visible !important; // wtf, why does global style have svg:not(:root) { visibility: hidden }
    min-width: 100%;
    height: 100%;
    margin: 0 auto;
    filter: drop-shadow(0 26px 32px rgba(55, 55, 255, 0.35));
    path.marker--court {
      opacity: 0.1;
    }
    path.marker--case-filed {
      &:hover {
        height: 200%;
      }
    }
  }
  .impact-map__tool-tip {
    position: absolute;
    z-index: 10;
    width: 200px;
    height: 120px;
    padding: 1em;
    border-radius: 6px;
    background: ${(props) => props.theme.colors.white[700]};
    border: 1px solid ${(props) => props.theme.colors.white[500]};
    border-bottom: 2px solid ${(props) => props.theme.colors.white[300]};
    box-shadow: ${(props) => props.theme.effects.shadow[350]};
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    p {
      margin: 0;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    min-height: 320px;
    .impact-map__wrapper {
      left: -52px;
      top: -24px;
    }
  }
`;

export default ImpactMap;
